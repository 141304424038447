<template>
  <el-dialog title="Add new report date" :visible.sync="dialogFormVisible" width="500px">
    <el-form :model="form" label-width="110px">
      <el-form-item label="Select Date">
        <el-date-picker
          v-model="form.reportDateStr"
          align="right"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="Please select">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">Cancel</el-button>
      <el-button type="primary" @click="confirm">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import Monitoring from '@/assets/icons/home/Monitoring.png'
  import Inspected from '@/assets/icons/home/Inspected.png'
  export default {
    components: {
      SelectDate: () => import('@/components/select-datetime/select-date')
    },
    data() {
      return {
        dialogTableVisible: false,
        dialogFormVisible: false,
        form: {
          reportDateStr: '',
        },
        formLabelWidth: '120px',
        monitoringIcon: Monitoring,
        inspectedIcon: Inspected
      }
    },

    methods: {
      open(row) {
        console.log('open', row)
        this.dialogFormVisible = true
      },

      confirm() {
        if (!this.form.reportDateStr) {
          this.$message.warning('Please select date')
          return
        }
        this.$emit('confirm', this.form.reportDateStr)
        this.dialogFormVisible = false
      }
    }
  }
</script>

<style scoped lang="scss">
.flex-center {
  display: flex;
  align-items: center;
  height: 40px;
  .el-image {
    margin-right: 8px;
  }
}
</style>